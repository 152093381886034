require('bootstrap')
require('./info_goto.js')
require('./seminar.js')
/*require('./top-button.js')*/
require('./_form.js')
require('./blog-post.js')

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

// Get holidays of this year and storing to local storage
const JapaneseHolidays = require('japanese-holidays');
const today = new Date();
const holidays = JapaneseHolidays.getHolidaysOf(today.getFullYear());

window.localStorage.setItem('japan_holidays', JSON.stringify(holidays));