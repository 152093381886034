$(document).ready(function () {
  //if you will test in your local please change the local host address
  var root_url = process.env.NODE_ENV === 'development' ? 'http://localhost:8888/' : 'https://www.ryugaku-johokan.com/';
  var wp_url = root_url + 'articles/wp-json/wp/v2';

  var newsLatestPosts = $.get(wp_url + '/categories?slug=news', function (data) {
    var category_id = data[0].id;
    var latest_posts = $('.latest-posts');
    var posts = latest_posts.find('.news-posts');
    var post = posts.find('.news-list');

    appendPost(wp_url, category_id, post, posts);
  });

  var mediaLatestPosts = $.get(wp_url + '/categories?slug=media', function (data) {
    var category_id = data[0].id;
    var latest_posts = $('.latest-posts');
    var posts = latest_posts.find('.media-posts');
    var post = posts.find('.media-list');

    appendPost(wp_url, category_id, post, posts);
  });
});

function appendPost(wp_url, category_id, article_element, parent_element) {
  $.ajax({
    url: wp_url + '/posts?categories=' + category_id + '&page=1&per_page=3',
    type: 'get',
    success: function (data) {
      $.each(data, function (key, article) {
        var date = new Date(article.date)
        var formattedDate = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();

        var new_post = article_element.clone();
        new_post.find('.date').html(formattedDate);
        new_post.find('.title').html(article.title.rendered);
        new_post.find('.title').attr('href', article.link);

        new_post.appendTo(parent_element);
      });
    }
  });
}