$('#send').click(function(e) {
  e.preventDefault();

  var isMobile= /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  var root_url = process.env.NODE_ENV === 'development' ? 'http://dev.nes.com/' : 'https://www.ryugaku-johokan.com/';

  $.ajax({
    url: root_url + "form/functions/post.php",
    type: 'post',
    data: $('#form').serialize(),
    dataType: 'json',
    success: function(result) {
      if (result.status == '200') {
        if (result.isContactOrLeadOrAccountExist) {
          window.location = window.location.origin + '/form/thanks/';
        } else {
          applyParamsToSalesForce();
        }
      } else {
        window.location = window.location.href;
        console.log(result.error_msg);
      }
    },
    error: function(xhr, ajaxOptions, thrownError) {
      console.log(xhr);
    }
  });


  function applyParamsToSalesForce () {
    var date = $("#preferred_date").val() ? $("#preferred_date").val().split('-') : "";
    var newDate = date[0] + '/' + date[1] + '/' + date[2];
    var date_two = $("#preferred_date2").val() ? $("#preferred_date2").val().split('-') : "";
    var new_date_two = date_two[0] + '/' + date_two[1] + '/' + date_two[2];
    var lp = "-", formref = "-", user_agent, source, medium, visitid, term, content, campaign, session_count, pageview_count, gclid;
    var name_kana = ($("#last_name").val() || $("#first_name").val()) ? $("#last_name").val() + " " + $("#first_name").val() : "";
    var email = $("#email").val();
    var address = $("#address").val() ? $("#address").val() + " " + $("#building").val() : "";
    var postal = $("#postal").val() ? $("#postal").val() : "";
    var prefecture = $("#prefecture").val() ? $("#prefecture").val() : "";
    var phone_no = $("#phone_no").val();
    var course = $("#course").val() ? $("#course").val() : "";
    var preferred_date = newDate + " " + $("#preferred_time").val();
    var preferred_date2 = new_date_two + " " + $("#preferred_time2").val();
    var preferred_time = $("#class_time").val();
    var message = $("#consultation").val() ? $("#consultation").val() : ""
                  + " \n "
                  + ($("#message").val() ? $("#message").val() : $("#remarks").val());

    var preferred_location = $("#preferred_location").val(); 
    var travel_plan = $("#travel_plan").val() ? $("#travel_plan").val() : $("#start_year").val() + ' ' + $("#start_month").val();
    var study_term = $("#period").val() ? $("#period").val() : $("#study_term").val();
    var school_name = $("#desired_school").val() ? $("#desired_school").val() : $("#school_name").val();
    var arrangement_period = $("#arrangement_period").val() ? $("#arrangement_period").val() : "";
    var country = $('#country').val();
    var seminar_contents = $('#seminar_contents').val();
    var seminar_place = $('#seminar_place').val();
    var seminar_date = $('#seminar_date').val() ? $('#seminar_date').val().split('-') : "";
    var SeminerDate = seminar_date[0] + '/' + seminar_date[1] + '/' + seminar_date[2];
    var seminar_time = $('#seminar_time').val();
    var campaign_id = $('#campaign_id').val();
    var storage = localStorage;
    var puropose_of_travel = $("#otherPurpose").val() ? $("#otherPurpose").val() : $("#purpose").val();
    var score_of_english = $("#score").val() ? $("#score").val() : "";
    var return_url = window.location.href.replace("confirm", "thanks");
    var lead_media = $("#media").val() ? $("#media").val() : " ";

    // Set Value of UserAgent
    user_agent = navigator.userAgent.toLowerCase();

    visitid = get_cookie();

    // Set Value of Landing Page and FormReference
    if (storage.getItem('landingpage')){
      lp = storage.getItem('landingpage');
    }
    if (storage.getItem('formref')){
      formref = storage.getItem('formref');
    }

    // Set Value of Campain Term
    if (term != ""){
      term = decodeURI(term);
      if (source == "google"){
        term = decodeURI(term);
      }
    }

    // Set Value of Campain Information
    get_campaign_info();

    // Data Apply to Each Input Form
    document.getElementById("return_url").value = return_url; /* url return after wtl process */
    document.getElementById("pardot_last_name").value = $("#last_name_kanji").val() ? $("#last_name_kanji").val() : $("#name").val(); /* User LastName */
    document.getElementById("pardot_first_name").value = $("#first_name_kanji").val() ? $("#first_name_kanji").val(): ""; /* User FirstName */
    document.getElementById("pardot_00N10000001Ze6I").value = name_kana; /* UserName Kana */
    document.getElementById("pardot_email").value = email; /* User Email */
    document.getElementById("pardot_00N10000001Ze6o").value = postal; /* Postal Code */
    document.getElementById("pardot_00N10000002bLGW").value = prefecture; /* Prefecture */
    document.getElementById("pardot_00N10000001Ze84").value = address; /* Address & Building */
    document.getElementById("pardot_00N10000001Ze8Y").value = phone_no ? phone_no : ""; /* phone number of user */
    document.getElementById("pardot_00N10000001Ze56").value = course; /* Requested Course */
    // document.getElementById("pardot_00N10000001Ze8E").value = ;
    // document.getElementById("pardot_00N10000001Zf2Y").value = country;
    document.getElementById("pardot_00N10000001Ze8E").value = message ? message : ""; /* Message, Notes */
    // document.getElementById("pardot_00N10000006HxBV").value = ;
    // document.getElementById("pardot_00N10000002bMx2").value = ;
    // document.getElementById("pardot_00N10000002bMx7").value = ;
    // document.getElementById("pardot_00N10000002bMxC").value = ;
    // document.getElementById("pardot_00N10000002bMxH").value = ;
    // document.getElementById("pardot_00N10000002bMxM").value = ;
    // document.getElementById("pardot_00N10000002bMxR").value = ;
    document.getElementById("pardot_00N10000001Zf29").value = study_term ? study_term : ""; /* Study Term */
    document.getElementById("pardot_00N10000001Ze6h").value = school_name ? school_name : ""; /* School Name */
    // document.getElementById("pardot_00N10000001Ze7Q").value = ;
    // document.getElementById("pardot_00N10000002bHB3").value = ;
    // document.getElementById("pardot_00N10000002bLGR").value = ;
    document.getElementById("pardot_00N10000002bLGM").value = arrangement_period; /* Arrangement Period for Staying Place */
    // document.getElementById("pardot_00N10000001ZgG6").value = ;
    document.getElementById("pardot_00N10000006HuTM").value = isMobile ? "留学情報館(モバイル)" : "留学情報館"; /* Lead Media */
    document.getElementById("pardot_00N10000006HuTq").value = lead_media; /* Contact Method from Lead */
    document.getElementById("pardot_00N10000006IFEX").value = user_agent; /* User Agent Infromation */
    document.getElementById("pardot_00N10000006ISJ5").value = lp; /* Landing Page Information */
    document.getElementById("pardot_00N10000006ISJF").value = formref; /* URL before coming to Form Page */
    document.getElementById("pardot_00N10000006HuU0").value = medium; /* Leading Media(Original Media before coming to EnglishPedia) */
    document.getElementById("pardot_00N10000006IOQ3").value = visitid; /* VisitID */
    document.getElementById("pardot_00N10000006IOQI").value = source; /* Campaign_Source */
    document.getElementById("pardot_00N10000006IOQ8").value = medium; /* Campaign_Medium */
    document.getElementById("pardot_00N10000006IOQN").value = term; /* Campaign_Term */
    // document.getElementById("pardot_00N10000006IOQD").value = content; /* Campaign_Content */
    document.getElementById("pardot_00N10000006IOQS").value = campaign; /* Campaign_CampaignName */
    document.getElementById("pardot_00N10000006IOQX").value = session_count; /* Count of Session */
    document.getElementById("pardot_00N10000006IOQc").value = pageview_count; /* Count of Pageviews */
    document.getElementById("pardot_00N10000006IFDy").value = gclid; /* GCLID */
    document.getElementById("pardot_00N10000003m9HI").value = seminar_contents; /* Seminar_ntent */
    document.getElementById("pardot_00N10000003m9HS").value = seminar_place; /* Seminar_place */
    document.getElementById("pardot_00N10000003m9HN").value = SeminerDate; /* Seminar_date */
    document.getElementById("pardot_00N10000006Yuad").value = seminar_time; /* Seminar_time */
    document.getElementById("pardot_Campaign_ID").value = campaign_id; /* campaign_id */
    document.getElementById("pardot_00N10000002bMws").value = score_of_english; /* User's English score */

    $('#pardot_form').attr('action', '/salesforce/lead.php').submit();

    function get_cookie() {
      var name = '_ga';
      var cookie_value = '';
      if (window.navigator.cookieEnabled) {
        // °kÐÐ¤·¤¿¥¯¥Ã¥­©`¤ÎÈ¡µÃ£¨Õi¤ßÞz¤ß£©
        if (document.cookie) {
          var cookies = document.cookie.split("; ");
          for (var i = 0; i < cookies.length; i++) {
            var str = cookies[i].split("=");
            if (str[0] == name) {
              cookie_value = unescape(str[1]);
              break;
            }
          }
        }
      } else {
        // ¥¯¥Ã¥­©`¤ÎÊÜ¤±Èë¤ì¤¬Ÿo„¿•r¤Î„IÀí
      }
      return cookie_value;
    }

    function get_campaign_info() {
      var utma = get_utm_value(document.cookie, '__utma=', ';');
      var utmb = get_utm_value(document.cookie, '__utmb=', ';');
      var utmc = get_utm_value(document.cookie, '__utmc=', ';');
      var utmz = get_utm_value(document.cookie, '__utmz=', ';');

      source = get_utm_value(utmz, 'utmcsr=', '|');
      medium = get_utm_value(utmz, 'utmcmd=', '|');
      term = get_utm_value(utmz, 'utmctr=', '|');
      content = get_utm_value(utmz, 'utmcct=', '|');
      campaign = get_utm_value(utmz, 'utmccn=', '|');
      gclid = get_utm_value(utmz, 'utmgclid=', '|');

      session_count = get_session_count(utma);
      pageview_count = get_pageview_count(utmb, utmc);

      if (gclid != "-") {
        source = 'google';
        medium = 'cpc';
      }
    }

    function get_utm_value(l, n, s) {
      if (!l || l == "" || !n || n == "" || !s || s == "")
        return "-";
      var i, j, k, utm = "-";
      i = l.indexOf(n);
      k = n.indexOf("=") + 1;

      if (i > -1) {
        j = l.indexOf(s, i);
        if (j < 0) {
          j = l.length;
        }
        utm = l.substring((i + k), j);
      }
      return utm;
    }

    // This function extracts the "Count of Sessions" value from the _utma cookie
    function get_session_count(str) {
      var i, vc = '-';
      if (str != '-') {
        i = str.lastIndexOf(".");
        i++;
        vc = str.substring(i);
      }
      return vc;
    }

    // This function extracts the "Count of Pageviews" value from the _utmb cookie
    function get_pageview_count(utmb, utmc) {
      var i, j, pc = '-';
      if (utmb != '-' && utmc != '-') {
        utmc = utmc + '.';

        i = utmc.length;
        j = utmb.indexOf(".", i);
        pc = utmb.substring(i, j);
      }
      return pc;
    }
  }
});