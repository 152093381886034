$('#submit').click(function (event) {
  event.preventDefault();

  const form = $('#seminar-form');
  $.ajax({
    url: form.attr('action'),
    type: form.attr('method'),
    data: form.serialize(),
    dataType: 'json',
    success: function(response) {
      if (response.status_code === 422) {
        $('#error').css('display', 'block');

        $('div#error > ul').html('');

        $.each(response.error_message, function(key, value) {
          $('div#error > ul').append('<li>' + value + '</li>');
        });

        document.getElementById('error').scrollIntoView(true);
      } else if (response.status_code === 200) {
        var href = window.location.href.replace(/seminar.*|program.*/g, response.redirect_url);

        window.location.href = href;
      }
    },
    error: function(xhr, ajaxOptions, thrownError) {
      console.log(thrownError);
    }
  });
});

$('#cancel').click(function () {
  localStorage.setItem("isBack", true);
});

if (localStorage.isBack) {
  document.getElementById('sf-section').scrollIntoView(true);
}

localStorage.removeItem("isBack");

