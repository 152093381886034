$('#submit_goto').click((e) => {
  e.preventDefault();

  const form = $('#goto_form');

  // Validation for required inputs
  $.ajax({
    url: form.attr('action'),
    type: form.attr('method'),
    data: form.serialize(),
    dataType: 'json',
    success: function(response) {
      if (response.status_code === 422) {
        $('#error').css('display', 'block');

        $('div#error > ul').html('');

        $.each(response.error_message, function(key, value) {
          $('div#error > ul').append('<li>' + value + '</li>');
        });

        document.getElementById('error').scrollIntoView(true);
      } else if (response.status_code === 200) {
        // Send data to SF

        // Disable button and applying 'wait' cursor
        $('#submit_goto').attr("disabled", true);
        $('#submit_goto').css({
          "background": "#faa0ac",
          "cursor": "wait",
          "border-bottom": "solid 4px #bf7599"
        });
        $('#spinner').css({"display": "inline-block"});

        applyParamsToSalesForce();
        sendGotoInfo(form.serializeArray());
      }
    },
    error: function(xhr, ajaxOptions, thrownError) {
      console.log(thrownError);
    }
  });

  $('body').css('cursor', 'auto'); 

  function applyParamsToSalesForce () {
    var visitid = get_cookie();
    var storage = localStorage;

    function get_cookie() {
      var name = '_ga';
      var cookie_value = '';
      if (window.navigator.cookieEnabled) {
        // 発行したクッキーの取得（読み込み）
        if (document.cookie) {
          var cookies = document.cookie.split("; ");
          for (var i = 0; i < cookies.length; i++) {
            var str = cookies[i].split("=");
            if (str[0] == name) {
              cookie_value = unescape(str[1]);
              break;
            }
          }
        }
      } else {
        // クッキーの受け入れが無効時の処理
      }
      return cookie_value;
    }
  
    // Set Value of Landing Page and FormReference
    var lp = "-";
    var formref = document.referrer;
    if (storage.getItem('landingpage')){
      lp = storage.getItem('landingpage');
    }
    if (storage.getItem('formref')){
      formref = storage.getItem('formref');
    }
  
    var source, medium, term, content, campaign, gclid;
    if (term != ""){
      term = decodeURI(term);
      if (source == "google"){
        term = decodeURI(term);
      }
    }

    get_campaign_info();
    
    function get_campaign_info() {
      var utmz = get_utm_value(document.cookie, '__utmz=', ';');
  
      source = get_utm_value(utmz, 'utmcsr=', '|');
      medium = get_utm_value(utmz, 'utmcmd=', '|');
      term = get_utm_value(utmz, 'utmctr=', '|');
      content = get_utm_value(utmz, 'utmcct=', '|');
      campaign = get_utm_value(utmz, 'utmccn=', '|');
      gclid = get_utm_value(utmz, 'utmgclid=', '|');
  
      if (gclid != "-") {
        source = 'google';
        medium = 'cpc';
      }
    }
  
    function get_utm_value(l, n, s) {
      if (!l || l == "" || !n || n == "" || !s || s == "")
        return "-";
      var i, j, k, utm = "-";
      i = l.indexOf(n);
      k = n.indexOf("=") + 1;
  
      if (i > -1) {
        j = l.indexOf(s, i);
        if (j < 0) {
          j = l.length;
        }
        utm = l.substring((i + k), j);
      }
      return utm;
    }
  
    document.getElementById("visit_id").value = visitid; /* VisitID */
    document.getElementById("campaign_medium").value = medium; /* Campaign_Medium */
    document.getElementById("campaign_source").value = source; /* Campaign_Source */
    document.getElementById("campaign_name").value = campaign; /* Campaign_CampaignName */
    document.getElementById("campaign_term").value = term; /* Campaign_Term */
    document.getElementById("campaign_content").value = content; /* Campaign_Content */
    document.getElementById("landing_page").value = lp; /* Landing Page Information */
    document.getElementById("form_ref").value = formref; /* URL before coming to Form Page */
  }
});

function sendGotoInfo(data) {
  $.ajax({
    url: "https://www.ryugaku-johokan.com/form/functions/send_goto_info.php",
    type: "post",
    data: data,
    success: function(response) {
      // Redirect to thanks page
      var thanks_page = window.location.href.replace('/info/goto', '/info/goto/thanks');
      window.location.replace(thanks_page);
    },
    error: function(xhr, ajaxOptions, thrownError) {
      console.log(thrownError);
    }
  });
}

$('input[type="radio"]').click((e) => {
  let classIdentifier = e.target.getAttribute('identifier');

  if (e.target.value === "その他") {
    $('div#' + classIdentifier).css("display", "block");
    $('div#' + classIdentifier + " input[type='text']").removeAttr('disabled');
  } else {
    $("div#" + classIdentifier).css("display", "none");
    $('div#' + classIdentifier + " input[type='text']").attr("disabled", "disabled");
  }
});

$('input[type="checkbox"]').click((e) => {
  if (e.target.value === "その他") {
    $('#country-selection input#othercountry').attr("disabled", !e.target.checked);
    $('#country-selection').toggle(); 
  }
});

// Language abroad radio button listener
$('#gogaku').click(() => {
  $('#ryugaku_type').val($('#gogaku').attr('ryugaku-type'));
});

// University abroad radio button listener
$('#daigaku').click(() => {
  $('#ryugaku_type').val($('#daigaku').attr('ryugaku-type'));
});